import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ErrorToast,
  SuccessToast,
} from "../../../../../utils/toastNotifications";
import {
  getPatientAdmissionHourlyReportByCategory,
  postPatientAdmissionHourlyReport,
  putPatientAdmissionHourlyReport,
} from "../../../../../redux/actions/patientAdmissionHourlyReport";
import { v4 as uuidv4 } from 'uuid';
import SvgFilesIcon from "../../../../../components/SvgFiles/SvgFiles";
import { useSelector } from "react-redux";
import DeleteConfirmationModal from "../../../../../components/DeletePopupComponent/DeletePopup";

const PatientAdmissionSugar: React.FC<any> = ({
  selected_admission,
  selected_date,
}) => {
  const { user_info } = useSelector((state: any) => state?.user);
  const initialRows = useMemo(
    () =>
      Array.from({ length: 24 }).map((_, i) => {
        const startHour = (i % 24).toString().padStart(2, "0");
        const endHour = (i === 23) ? "24" : ((i % 24) + 1).toString().padStart(2, "0");
        const selected_time = `${startHour}:00-${endHour}:00`;
 
        return {
          id: uuidv4().toString(),
          datetime: new Date(),
          selected_time,
          rbs: "",
          insulin: "",
          route: "",
        };
      }),
    []
  );
 
  type Row = (typeof initialRows)[number];
  const [rows, setRows] = useState<Row[]>(initialRows);
  const [rowId, setrowId] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
  const admission_hourly_report = useCallback(async () => {
    if (selected_admission !== null) {
      const date = selected_date
        ? new Date(selected_date?.substring(0, 10))
        : new Date();
      const tommorow = new Date(date);
      tommorow.setDate(date?.getDate() + 1);
      const yestrday = new Date(date);
      yestrday.setDate(date?.getDate() - 1);
      yestrday.setHours(23, 59, 59, 999);
      const request = await getPatientAdmissionHourlyReportByCategory(
        selected_admission?.id,
        "sugar",
        new Date(yestrday).toISOString(),
        new Date(tommorow).toISOString()
      );
      if (request?.statusCode === 200) {
        const requestedData = request?.body?.data[0];
        setrowId(requestedData?.id);
        if (requestedData?.reportValues?.length > 0) {
          const reorderedArray = requestedData?.reportValues
          setRows(reorderedArray);
        } else {
          setRows(initialRows);
        }
      } else {
        setrowId('');
        setRows(initialRows);
      }
    }
  }, [selected_date, selected_admission, initialRows]);

  useEffect(() => {
    admission_hourly_report();
  }, [selected_date, selected_admission, admission_hourly_report]);

  const handleAdmissionHourlyReport = async () => {
    if (!rowId) {
      let jsonDaa = {
        reportValues: rows,
        category: "sugar",
        patient_admission_id: selected_admission?.id,
        datetime: selected_date
      };
      const response = await postPatientAdmissionHourlyReport(jsonDaa);
      if (response?.statusCode === 200) {
        SuccessToast(response?.message)
        admission_hourly_report();
      }
    } else {
      const request = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: rows });
      if (request?.statusCode === 200) {
        SuccessToast(request?.message)
        admission_hourly_report();
      } else {
        ErrorToast(request?.message)
      }
    }
  }
  const handleDeleteHourlyReport = useCallback(
    async (id: string) => {
      try {
        const isRowSaved = rows.some(row => row.id === id && rowId);
        if (!isRowSaved) {
          return ErrorToast("Record is not saved yet!");
        }
        const updatedRows = rows.map((row) => {
          if (row.id === id) {
            return {
              ...row,
              rbs: "",
              insulin: "",
              route: "",
            };
          }
          return row;
        });
        const response = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: updatedRows });
        if (response?.statusCode === 200) {
          SuccessToast("Row Deleted Successfully");
          admission_hourly_report();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [rows, rowId, admission_hourly_report]
  );


  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setCurrentDeleteId(null);
  };

  const confirmDelete = () => {
    if (currentDeleteId) {
      handleDeleteHourlyReport(currentDeleteId);
    }
    closeDeleteModal();
  };
  // const onchageTexfields = useCallback(
  //   (event: any, index: number) => {
  //   const { name, value } = event.target;
  //   const updatedRows = rows.map((row, i) => i === index ? {
  //     ...row,
  //     [name]: value.replace(/[^0-9.]/g, '') // Allow numbers and dots
  //     .replace(/(\..*)\./g, '$1') // Allow only one dot
  //     .replace(/(\.\d{2})\d+/g, '$1') // Allow up to two decimal places
  //       }:row);
  //   setRows(updatedRows);
  // },[rows]);
  const onchageTexfields = useCallback(
    (event: any, index: number) => {
      const { name, value } = event.target;
      let formattedValue = '';
      if (name === 'rbs' || name === 'route') {
        let hasSlash = value.includes('/');
        // Split the value into parts before and after the slash
        const [beforeSlash, afterSlash] = value.split('/');
        // Format the part before the slash
        const formattedBeforeSlash = (beforeSlash || '')
          .replace(/[^0-9.]/g, '') // Allow numbers and dots
          .replace(/(\..*)\./g, '$1') // Allow only one dot
          .replace(/(\.\d{2})\d+/g, '$1'); // Allow up to two decimal places
        const formattedAfterSlash = (afterSlash || '')
          .replace(/[^0-9.]/g, '') // Allow numbers and dots
          .replace(/(\..*)\./g, '$1') // Allow only one dot
          .replace(/(\.\d{2})\d+/g, '$1'); // Allow up to two decimal places
        if (hasSlash) {
          formattedValue = `${formattedBeforeSlash}/${formattedAfterSlash}`;
        } else {
          formattedValue = formattedBeforeSlash;
        }
        // Ensure only one slash is allowed
        const parts = formattedValue.split('/');
        if (parts.length > 2) {
          // If there are more than 2 parts, we only keep the first two
          formattedValue = parts.slice(0, 2).join('/');
        }
      } else {
        // Allow only numbers and dots for other fields, with up to two decimal places
        formattedValue = value
          .replace(/[^0-9.]/g, '') // Allow numbers and dots
          .replace(/(\..*)\./g, '$1') // Allow only one dot
          .replace(/(\.\d{2})\d+/g, '$1'); // Allow up to two decimal places
      }
      const updatedRows = rows.map((row, i) =>
        i === index ? { ...row, [name]: formattedValue } : row
      );
      setRows(updatedRows);
    },
    [rows]
  );
  
  const shouldShowActions = () => {
    return (
      (user_info?.is_super_admin ||
        new Date(selected_date).toDateString() === new Date().toDateString()) &&
      selected_admission?.admitted &&
      !selected_admission?.discharged
    );
  };

  return (
    <>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="patient admission sugar"
      />
      {shouldShowActions() && (
        <div className="report-save-all-btn">
          <button className="btn btn-primary mr-2 medium-btn" onClick={handleAdmissionHourlyReport}>
            Save All
          </button>
        </div>)}
        <div className="hourly-table-scroll">
          <table className="table table-hover fixed-table-top">
            <thead className="table-light">
              <tr>
                <th className="time-col">Time</th>
                <th>RBS (MG/DL)</th>
                <th>Insulin (UNIT)</th>
                <th>Route (IV/SC)</th>
                {shouldShowActions() && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {rows.map((row: any, index: number) => {
                  const isRowEmpty =
                  !row.rbs && !row.bp && !row.insulin && !row.route;
                return(
               <tr key={index}>
                  <td className="time-col">{row?.selected_time}</td>
                  <td><input type="text" name="rbs" value={row?.rbs} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                  <td><input type="text" name="insulin" value={row?.insulin} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                  <td><input type="text" name="route" value={row?.route} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                  {shouldShowActions() && (
                    <td className="hospital-card-box">
                      <div className="user-setting-box">
                      <div
                          className={`delete-box ${isRowEmpty ? 'disabled delete-box-disable' : ''}`}
                          title={isRowEmpty ? 'No Data to Reset' : 'Reset'}
                          onClick={() => !isRowEmpty && openDeleteModal(row.id)}
                          style={{ pointerEvents: isRowEmpty ? 'none' : 'auto' }}
                        >
                        <i className="fas fa-redo"></i>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
  );
};
 
export default PatientAdmissionSugar;

