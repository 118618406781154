import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
// import 'firebase/firestore';
// import 'firebase/auth';
// import 'firebase/storage';
 
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAvz3NI05KTqEXUTwnPYcGthJPqeKzHHd0",
  authDomain: "entelteleicuproject.firebaseapp.com",
  projectId: "entelteleicuproject",
  storageBucket: "entelteleicuproject.firebasestorage.app",
  messagingSenderId: "368960572728",
  appId: "1:368960572728:web:a9efd3b2dc9a3f3d02c5cd"
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
 
 
// Export data storage service
// export const db = firebase.firestore();
 
// Export auth service
// export const auth = firebase.auth();
 
// Request for client token for recieving notifications
// export const requestFirebaseNotificationPermission = () =>
//   new Promise((resolve, reject) => {
//     messaging
//       .getToken()
//       .then((firebaseToken) => {
//         resolve(firebaseToken);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
 
// When notification is recieved
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });
 
export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};
 
export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
        "BLlElqJTZH9zN4AHZ0MzwqrgBNRUeoBhDcGo60HK3gvkIlLZ8o38US0qB_11U1DH6Y-BNw9JbISHdwkDej7-Z58",
      serviceWorkerRegistration,
    })
  );
 
export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
 
 