import React, { useEffect } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
} from "reactstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
// import images
import profile from "../../assets/images/login.png";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import { reset_password } from "../../redux/actions/userAction";

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Minimum 6 characters are required")
    .max(15, "Maximum 15 characters are required")
    .matches(/\d/, "Atleast one number is required")
    .matches(/[A-Z]+/, "Atleast one capital letter is required")
    .matches(/[\W_]+/, "Atleast one special character is required"),
  confirm_password: Yup.string().required("Confirm password is required"),
});

const ResetPassword: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.email === undefined) {
      navigate("/forgot-password");
      ErrorToast("Session Expired");
    }
  }, [location, navigate]);

  const submitForm = async (values: any) => {
    let password_change = await reset_password(
      location?.state?.email,
      location?.state?.otp,
      values.password,
      values?.confirm_password
    );

    if (password_change?.statusCode === 200) {
      SuccessToast("Password changed successfully");
      navigate("/");
    } else {
      ErrorToast(password_change?.errors[0]?.msg);
    }
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mt-5">
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Link to="/" className="text-center login-img">
                      <img src={profile} alt="" className="img-fluid" />
                    </Link>
                    <Formik
                      initialValues={{
                        password: "",
                        confirm_password: "",
                      }}
                      validationSchema={ResetPasswordSchema}
                      onSubmit={submitForm}
                    >
                      {(formik) => {
                        const {
                          values,
                          handleChange,
                          handleSubmit,
                          errors,
                          touched,
                          handleBlur,
                          isValid,
                          dirty,
                        } = formik;
                        return (
                          <Form
                            className="form-horizontal"
                            onSubmit={handleSubmit}
                          >
                            <h4 className="card-title mb-3 mt-2">
                              Reset Password
                            </h4>
                            <div className="mb-3">
                              <Label className="form-label">New Password</Label>
                              <Input
                                className="form-control"
                                placeholder="Enter Current Password"
                                type="password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.password && touched.password && (
                                <span className="error">{errors.password}</span>
                              )}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">
                                Confirm Password
                              </Label>
                              <Input
                                className="form-control"
                                type="password"
                                name="confirm_password"
                                placeholder="Repeat New Password"
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.confirm_password &&
                                touched.confirm_password && (
                                  <span className="error">
                                    {errors.confirm_password}
                                  </span>
                                )}
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className={
                                  dirty && isValid
                                    ? "btn btn-primary btn-block"
                                    : "btn btn-primary btn-block disabled-btn"
                                }
                                disabled={!(dirty && isValid)}
                                type="submit"
                              >
                                Reset Password
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>

                    <div className="mt-4 text-center text-muted">
                      Remember Password?
                      <Link to="/" className="text-secondry">
                        &nbsp; Login
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p className="text-muted">
                  © {new Date().getFullYear()} Entel. Crafted With{" "}
                  <i className="mdi mdi-heart text-danger" /> By{" "}
                  <a
                    className="text-muted"
                    href="https://www.atozinfoway.com/"
                    title="AtoZ Infoway"
                  >
                    {" "}
                    AtoZ Infoway.
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <p className="account-pages-footer">
          <Link className="text-muted" to="/">
            Privacy Policy{" "}
          </Link>{" "}
          &nbsp; &nbsp; | &nbsp; &nbsp;
          <Link className="text-muted" to="/">
            Terms & Conditions
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
