import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ErrorToast,
  SuccessToast,
} from "../../../../../utils/toastNotifications";
import {
  getPatientAdmissionHourlyReportByCategory,
  postPatientAdmissionHourlyReport,
  putPatientAdmissionHourlyReport,
} from "../../../../../redux/actions/patientAdmissionHourlyReport";
import DeleteConfirmationModal from "../../../../../components/DeletePopupComponent/DeletePopup";
import { v4 as uuidv4 } from 'uuid';
import SvgFilesIcon from "../../../../../components/SvgFiles/SvgFiles";
import { useSelector } from "react-redux";
const PatientAdmissionHRVentilator: React.FC<any> = ({
  selected_admission,
  selected_date,
}) => {
  const initialRows = useMemo(
    () =>
      Array.from({ length: 24 }).map((_, i) => {
        const startHour = (i % 24).toString().padStart(2, "0");
        const endHour = (i === 23) ? "24" : ((i % 24) + 1).toString().padStart(2, "0");
        const selected_time = `${startHour}:00-${endHour}:00`;
 
        return {
          id: uuidv4().toString(),
          datetime: new Date(),
          selected_time,
          etv: "",
          pip: "",
          p_plat: "",
          respiration_rate: "",
          peep: "",
        };
      }),
    []
  );

  type Row = (typeof initialRows)[number];
  const [rows, setRows] = useState<Row[]>(initialRows);
  const [rowId, setrowId] = useState('');
  const { user_info } = useSelector((state: any) => state?.user);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<string | null>(null);
  const admission_hourly_report = useCallback(async () => {
    if (selected_admission !== null) {
      const date = selected_date
        ? new Date(selected_date?.substring(0, 10))
        : new Date();
      const tommorow = new Date(date);
      tommorow.setDate(date?.getDate() + 1);
      const yestrday = new Date(date);
      yestrday.setDate(date?.getDate() - 1);
      yestrday.setHours(23, 59, 59, 999);
      const request = await getPatientAdmissionHourlyReportByCategory(
        selected_admission?.id,
        "patient_data",
        new Date(yestrday).toISOString(),
        new Date(tommorow).toISOString()
      );
      if (request?.statusCode === 200) {
        const requestedData = request?.body?.data[0];
        setrowId(requestedData?.id);
        if (requestedData?.reportValues?.length > 0) {
          const reorderedArray = requestedData?.reportValues
          setRows(reorderedArray);
        } else {
          setRows(initialRows);
        }
      } else {
        setrowId('');
        setRows(initialRows);
      }
    }
  }, [selected_date, selected_admission]);

  useEffect(() => {
    admission_hourly_report();
  }, [selected_date, selected_admission, admission_hourly_report]);

  const handleDeleteHourlyReport = useCallback(
    async (id: string) => {
      try {
        const isRowSaved = rows.some(row => row.id === id && rowId);
        if (!isRowSaved) {
          return ErrorToast("Record is not saved yet!");
        }
        const updatedRows = rows.map((row) => {
          if (row.id === id) {
            return {
              ...row,
              tv: "",
              ip: "",
              ventilator_respiration_rate: "",
              i_e_ratio: "",
              inspiratory_time: "",
              peep: "",
              fio2: "",
            };
          }
          return row;
        });
        const response = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: updatedRows });
        if (response?.statusCode === 200) {
          SuccessToast("Row Deleted Successfully");
          admission_hourly_report();
        } else if (response?.statusCode === 400)
          ErrorToast(response?.errors[0]?.msg);
        else ErrorToast("Unknown Error Occurred!");
      } catch (error: any) {
        ErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
    [rows, rowId, admission_hourly_report]
  );


  const openDeleteModal = (id: string) => {
    setCurrentDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setCurrentDeleteId(null);
  };

  const confirmDelete = () => {
    if (currentDeleteId) {
      handleDeleteHourlyReport(currentDeleteId);
    }
    closeDeleteModal();
  };


  const handleAdmissionHourlyReport = async () => {
    if (!rowId) {
      let jsonDaa = {
        reportValues: rows,
        category: "ventilator",
        patient_admission_id: selected_admission?.id,
        datetime: selected_date
      };
      const response = await postPatientAdmissionHourlyReport(jsonDaa);
      if (response?.statusCode === 200) {
        SuccessToast(response?.message)
        admission_hourly_report();
      }
    } else {
      const request = await putPatientAdmissionHourlyReport({ id: rowId, reportValues: rows });
      if (request?.statusCode === 200) {
        SuccessToast(request?.message)
        admission_hourly_report();
      } else {
        ErrorToast(request?.message)
      }
    }
  }
  const onchageTexfields = useCallback(
    (event: any, index: number) => {
    const { name, value } = event.target;
    const updatedRows = rows.map((row, i) => i === index ? {
      ...row,
      [name]: value.replace(/[^0-9.]/g, '') // Allow numbers and dots
      .replace(/(\..*)\./g, '$1') // Allow only one dot
      .replace(/(\.\d{2})\d+/g, '$1') // Allow up to two decimal places
    } : row);
      setRows(updatedRows);
  },[rows])
  const shouldShowActions = () => {
    return (
      (user_info?.is_super_admin ||
        new Date(selected_date).toDateString() === new Date().toDateString()) &&
      selected_admission?.admitted &&
      !selected_admission?.discharged
    );
  };

  return (
    <>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        onConfirm={confirmDelete}
        pageName="patient admission ventilator"
      />
      {shouldShowActions() && (
        <div className="report-save-all-btn">
          <button className="btn btn-primary mr-2 medium-btn" onClick={handleAdmissionHourlyReport} >
            Save All
          </button>
        </div>
      )}
      <div className="hourly-table-scroll">
        <table className="table table-hover fixed-table-top">
          <thead className="table-light">
            <tr>
              <th className="time-col">Time</th>
              <th>TV</th>
              <th>IP</th>
              <th>Ventilator Resporation Rate</th>
              <th>I E Ratio</th>
              <th>Inspiratory Time</th>
              <th>Peep</th>
              <th>Fio2</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row: any, index: number) => {
               const isRowEmpty =
               !row.tv && !row.ip && !row.ventilator_respiration_rate && !row.i_e_ratio && !row.inspiratory_time && !row.peep && !row.fio2;
             return (
              <tr key={index}>
                <td className="time-col">{row?.selected_time}</td>
                <td><input type="text" name="tv" value={row?.tv} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                <td><input type="text" name="ip" value={row?.ip} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                <td><input type="text" name="ventilator_respiration_rate" value={row?.ventilator_respiration_rate} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                <td><input type="text" name="i_e_ratio" value={row?.i_e_ratio} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                <td><input type="text" name="inspiratory_time" value={row?.inspiratory_time} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                <td><input type="text" name="peep" value={row?.peep} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                <td><input type="text" name="fio2" value={row?.fio2} onChange={(e) => onchageTexfields(e, index)} disabled={selected_admission?.discharged} /></td>
                {shouldShowActions() && (
                  <td className="hospital-card-box">
                    <div className="user-setting-box">
                    <div
                          className={`delete-box ${isRowEmpty ? 'disabled delete-box-disable' : ''}`}
                          title={isRowEmpty ? 'No Data to Reset' : 'Reset'}
                          onClick={() => !isRowEmpty && openDeleteModal(row.id)}
                          style={{ pointerEvents: isRowEmpty ? 'none' : 'auto' }}
                        >                       
                         <i className="fas fa-redo"></i>
                      </div>
                    </div>
                  </td>
                )}
              </tr>
             )
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PatientAdmissionHRVentilator;

