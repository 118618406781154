import { useEffect, useState, MouseEvent, useCallback, useRef } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import maleAvatar from "../../../assets/images/users/male_avatar.png";
import femaleAvatar from "../../../assets/images/users/female_avatar.png";
import { getPatientAdmissionPrescriptionByPatientAdmissionId } from "../../../redux/actions/patientAdmissionPrescription";
import { IPrescription } from "../../../redux/types/patientAdmissionPrescriptionTypes";
import { formatDateToLocaleDateString } from "../../../helpers/helperFunctions";
import { getPatientAdmissionGeneralExaminationByPatientAdmissionId } from "../../../redux/actions/patientAdmissionGeneralExamination";
import { IPatientAdmissionGeneralExaminationTypes } from "../../../redux/types/patientAdmissionGeneralExaminationTypes";
import oxygen from '../../../assets/images/oxygen.png';
import Critical from '../../../assets/images/Critical.png';
import ventilator from '../../../assets/images/ventilator.png';
import { getPatientAdmissionSystemicExaminationByPatientAdmissionId } from "../../../redux/actions/patientAdmissionSystemicExamination";
import { IPatientAdmissionSystemicExaminationTypes } from "../../../redux/types/patientAdmissionSystemicExaminationTypes";
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, } from "reactstrap";
import { putPatientAdmission } from "../../../redux/actions/patientAction";
import { SuccessToast } from "../../../utils/toastNotifications";
import { getPatientDocumentsByPatientAdmissionId } from "../../../redux/actions/patientDocuments";
import { IPatientDocuments } from "./patientAdmissionsDetails/patientDocuments";
import { getPatientAdmissionNotificationsByPatientAdmissionId } from "../../../redux/actions/patientAadmissionNotificationsAction";
import { useSelector } from "react-redux";
import CustomMUIDatePicker from "../../../components/UI/CustomMUIDatePicker";
import React from "react";
import DOMPurify from "dompurify";
import SvgFilesIcon from "../../../components/SvgFiles/SvgFiles";
import { Tooltip } from "@mui/material";

function getStartOfDayISO(date: string | number | Date) {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  const ISTOffset = 5.5 * 60 * 60 * 1000;
  const startOfDayInIST = new Date(startOfDay.getTime() + ISTOffset);
  return startOfDayInIST.toISOString();
}

function getEndOfDayISO(date: string | number | Date) {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  const ISTOffset = 5.5 * 60 * 60 * 1000;
  const endOfDayInIST = new Date(endOfDay.getTime() + ISTOffset);
  return endOfDayInIST.toISOString();
}
const PatientDetailDashboard: React.FC<any> = ({
  patient,
  selected_admission,
  patientChangesFlags,
  activeTab,
}) => {
  const [menu, setMenu] = useState(false);
  const { user_info } = useSelector((state: any) => state.user);
  const [prescriptions, setPrescriptions] = useState<IPrescription[]>([]);
  const [genralExminations, setGenralExminations] = useState<IPatientAdmissionGeneralExaminationTypes[]>([]);
  const [systemicexaminations, setSystemicexaminations] = useState<IPatientAdmissionSystemicExaminationTypes[]>([]);
  const [patientDocuments, setPatientDocuments] = useState<IPatientDocuments[]>([]);
  const [allNotiData, setAllNotiData] = useState([]);
  const [selected_date, setSelectedDate] = useState<string>(getStartOfDayISO(new Date()));
  const [patientProfileData, setPatientProfileData] = useState<any>({
    "odp": selected_admission?.odp_note, "genralExminations": [], "patientDocuments": [], "systemicexaminations": [], "prescriptions": [], "Document": [],
    "family_history": patient?.family_history, "family_blood_diseases": patient?.family_blood_diseases, "medication": patient?.medication, "injury": patient?.injury, "behavioural_illness": patient?.behavioural_illness, "childhood_illness": patient?.childhood_illness,
    "surgical": patient?.surgical, "medical": patient?.medical, "drug_allergies": patient?.drug_allergies
  })

  const is_patient_admitted =
    selected_admission &&
    selected_admission?.hospital_property?.id === user_info?.hospital_property?.id &&
    !selected_admission?.discharge &&
    selected_admission?.admitted;


  const handleViewDocument = async (fileName: any) => {
    try {
      window.open(fileName);
    } catch (error) {
      console.error('Error handling document:', error);
    }
  };

  const getFileTypeIcon = (fileExtension: any) => {
    let iconClass = "";
    if (fileExtension === 'pdf') {
      iconClass = "mdi mdi-file-pdf text-danger";
    } else if (fileExtension === 'docx' || fileExtension === 'doc') {
      iconClass = "mdi mdi-file-word text-primary";
    } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
      iconClass = "mdi mdi-file-excel text-success";
    } else if (fileExtension.startsWith('png') || fileExtension.startsWith('jpg') || fileExtension.startsWith('jpeg') || fileExtension.startsWith('gif')) {
      iconClass = "mdi mdi-file-image text-success";
    } else {
      iconClass = "mdi mdi-file text-secondary";
    }
    return iconClass;
  };

  // Added useRef to store previous values
  const prevProps = useRef({
    spo2: patient?.spo2,
    output: patient?.output,
    input: patient?.input,
    temperature: patient?.temperature,
    pulse: patient?.pulse,
    bp: patient?.bp
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const generalExaminationsResponse = await getPatientAdmissionGeneralExaminationByPatientAdmissionId(selected_admission?.id);
        const systemicExaminationsResponse = await getPatientAdmissionSystemicExaminationByPatientAdmissionId(selected_admission?.id);
        const prescriptionsResponse = await getPatientAdmissionPrescriptionByPatientAdmissionId(selected_admission?.id);
        const documentsResponse = await getPatientDocumentsByPatientAdmissionId(selected_admission.id);
        const generalExaminationsData = generalExaminationsResponse?.statusCode === 200 ? generalExaminationsResponse.body?.data : [];
        const systemicExaminationsData = systemicExaminationsResponse?.statusCode === 200 ? systemicExaminationsResponse.body?.data : [];
        const prescriptionsData = prescriptionsResponse?.statusCode === 200 ? prescriptionsResponse.body?.data : [];
        const documentsData = documentsResponse?.statusCode === 200 ? documentsResponse.body?.data.slice(0, 5) : [];
        setGenralExminations(generalExaminationsData);
        setSystemicexaminations(systemicExaminationsData);
        setPrescriptions(prescriptionsData);
        setPatientDocuments(documentsData);
        setPatientProfileData({
          odp: selected_admission?.odp_note,
          genralExminations: generalExaminationsData,
          systemicexaminations: systemicExaminationsData,
          prescriptions: prescriptionsData,
          Document: documentsData,
          family_history: patient?.family_history,
          family_blood_diseases: patient?.family_blood_diseases,
          medication: patient?.medication,
          surgical: patient?.surgical,
          behavioural_illness: patient?.behavioural_illness,
          drug_allergies: patient?.drug_allergies,
          childhood_illness: patient?.childhood_illness,
          medical: patient?.medical,
          injury: patient?.injury
        })
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    if (selected_admission !== null && patient) {
      fetchData();
    } else if (!selected_admission) {
      setGenralExminations([]);
      setSystemicexaminations([]);
      setPrescriptions([]);
      setPatientDocuments([]);
      setPatientProfileData({
        odp: "",
        genralExminations: [],
        systemicexaminations: [],
        prescriptions: [],
        Document: [],
        family_history: patient?.family_history,
        family_blood_diseases: patient?.family_blood_diseases,
        medication: patient?.medication,
        surgical: patient?.surgical,
        behavioural_illness: patient?.behavioural_illness,
        drug_allergies: patient?.drug_allergies,
        childhood_illness: patient?.childhood_illness,
        medical: patient?.medical,
        injury: patient?.injury
      })
    }
  }, [selected_admission, activeTab]);

  useEffect(() => {
    if (patient) {
      const { spo2, output, input, temperature, pulse, bp } = patient;
      if (prevProps.current.spo2 !== spo2) {
        // Handle spo2 change
      }
      if (prevProps.current.output !== output) {
        // Handle output change
      }
      if (prevProps.current.input !== input) {
        // Handle input change
      }
      if (prevProps.current.temperature !== temperature) {
        // Handle temperature change
      }
      if (prevProps.current.pulse !== pulse) {
        // Handle pulse change
      }
      if (prevProps.current.bp !== bp) {
        // Handle bp change
      }
      // Update previous values
      prevProps.current = { spo2, output, input, temperature, pulse, bp };
    }
  }, [patient]);

  const dropdownClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setMenu(!menu);
  };

  const showNotification = (message: string) => {
    const notificationTitle = "Critical Patient Alert";
    const notificationOptions = {
      body: message,
      icon: "/logo64.png",
    };
    new Notification(notificationTitle, notificationOptions);
  };
 
  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.error("Notification permission denied");
    }
  };
 
 useEffect(()=>{
  requestNotificationPermission();
 },[])

  const hendalIsCritical = async (event: string = 'defaultEvent', successMessage: string) => {
    const payload: any = {};
    payload[event] = !selected_admission?.[event];
    if (event === "is_on_ventilator" && payload[event]) {
      payload["is_critical"] = true;
    } else if (event === "is_on_ventilator" && !payload[event]) {
      payload["is_critical"] = false;
    }
    try {
      await putPatientAdmission({
        ...payload,
        id: selected_admission?.id
      });
      selected_admission[event] = !selected_admission?.[event];
      const successMessages: { [key: string]: string } = {
        "is_critical": selected_admission?.[event] ? "Patient is Critical" : "Patient is not Critical",
        "is_on_ventilator": selected_admission?.[event] ? "Patient is Ventilator" : "Patient is not on Ventilator",
        "is_on_oxygen": selected_admission?.[event] ? "Patient is on Oxygen" : "Patient is not on Oxygen"
      };
      const successMessage = successMessages[event];
      if (successMessage) {
        SuccessToast(successMessage);
        patientChangesFlags();
      }
      if (payload["is_critical"]) {
        const fullName = `${patient.first_name} ${patient.middle_name || ''} ${patient.last_name}`;
        setTimeout(() => {
        showNotification(`${fullName} has been marked as critical.`);
      }, 60000);
      }
    } catch (error) {
      console.error("Error while updating patient admission:", error);
    }
  }

  const getAllNotificationsByPatientAdmissionId = async () => {
    if (selected_admission?.id) {
      const startDate = getStartOfDayISO(selected_date);
      const endDate = getEndOfDayISO(selected_date);
      const allNotifications = await getPatientAdmissionNotificationsByPatientAdmissionId(startDate, endDate, selected_admission?.id);
      if (allNotifications?.statusCode === 200) {
        setAllNotiData(allNotifications?.body?.data);
      } else {
        setAllNotiData([]);
      }
    } else if (!selected_admission) {
      setAllNotiData([]);
    }
  }

  useEffect(() => {
    getAllNotificationsByPatientAdmissionId();
  }, [selected_date, selected_admission, activeTab]);

  const handleAdmissionDateChange = (selectedDate: string) => {
    setSelectedDate(getStartOfDayISO(selectedDate));
  };

  const getNotificationString = (notification: any, index: number) => {
    const notificationDate = new Date(notification.created_on);
    const time = notificationDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const className =
      index % 3 === 0
        ? "one-color"
        : index % 3 === 1
          ? "two-color"
          : "three-color";
    return (
      <li key={notification.id}>
        <span className={className}>
          {notification.notification_text.charAt(0)}
        </span>
        <b>{notification.notification_text}</b> has been {notification.notification_type.toLowerCase()} on {time}
      </li>
    );
  };

  return (
    <div>
      <div className="patient-profile-inner-section">
        <div className="patient-profile-details-box">
          <div className="patient-title-box">
            <div className="icon-box">
              <SvgFilesIcon iconname={patient?.sex || "male"} />
            </div>
            <div className="name-box">
              <h4> {patient?.first_name} {patient?.middle_name}{" "}{patient?.last_name}</h4>
              <p>Age: {patient?.age} |  {patient?.sex === "male" ? "Male" : patient?.sex === "female" ? "Female" : "-"} |  Height: {patient?.height ? patient?.height : "-"} | Weight: {patient?.weight ? patient?.weight : "-"}</p>
              {/* <p>Patient ID : 83947</p> */}
            </div>
            <div className="patient-personal-info-box admission-doctor">
              <div className="patient-left-box">
                <div className="common-box">
                  <span>Admissin Doctor</span>
                  <p>{selected_admission?.admission_by_doctor?.name ? selected_admission?.admission_by_doctor?.name : "-"}</p>
                </div>
                <div className="common-box">
                  <span>Admissin Date</span>
                  <p>{selected_admission?.admission_date ? formatDateToLocaleDateString(selected_admission?.admission_date) : "-"}</p>
                </div>
              </div>
            </div>
            {is_patient_admitted && (
              <div className="card-dropdown">
                <div className="demo" onClick={(e) => e.stopPropagation()}>
                  <Dropdown isOpen={menu} toggle={dropdownClick} className="d-inline-block drop-menu-right">
                    <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
                      <SvgFilesIcon iconname={"more"} />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end more-dropdown">
                      <DropdownItem /* onClick={handleEditButton} */ tag="a" className="cursor-pointer">Edit</DropdownItem>
                      {!selected_admission?.is_critical && (
                        <DropdownItem className="critical-icon-box" tag="a" onClick={() => hendalIsCritical("is_critical", "Patient marked as Critical")}>Mark As Critical </DropdownItem>
                      )}
                      {selected_admission?.is_critical && (
                        <DropdownItem className="critical-icon-box" tag="a" onClick={() => hendalIsCritical("is_critical", "Patient marked as Not Critical")}>Mark As Not Critical </DropdownItem>
                      )}

                      {!selected_admission?.is_on_ventilator && (
                        <DropdownItem className="ventilator-icon-box" tag="a" onClick={() => hendalIsCritical("is_on_ventilator", "Patient marked as Ventilator")}>Mark As Ventilator </DropdownItem>
                      )}
                      {selected_admission?.is_on_ventilator && (
                        <DropdownItem className="ventilator-icon-box" tag="a" onClick={() => hendalIsCritical("is_on_ventilator", "Patient marked as Not Ventilator")}>Mark As Not Ventilator </DropdownItem>
                      )}

                      {!selected_admission?.is_on_oxygen && (
                        <DropdownItem className="oxygen-icon-box" tag="a" onClick={() => hendalIsCritical("is_on_oxygen", "Patient marked as Oxygen")}>Mark As Oxygen </DropdownItem>
                      )}
                      {selected_admission?.is_on_oxygen && (
                        <DropdownItem className="oxygen-icon-box" tag="a" onClick={() => hendalIsCritical("is_on_oxygen", "Patient marked as Not Oxygen")}>Mark As Not Oxygen </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </div></div>
            )}

            <div className="status-box">
              <div className="condition-icons-box">
                {is_patient_admitted && selected_admission && (<>
                  {
                    selected_admission?.is_on_oxygen && (
                      <Tooltip title="Oxygen" arrow>
                        <div className="oxygen-icon-box">
                          <SvgFilesIcon iconname={"oxygen"} />
                        </div>
                      </Tooltip>
                    )
                  }
                  {selected_admission?.is_critical && (
                    <Tooltip title="Critical" arrow>
                      <div className="critical-icon-box">
                        <SvgFilesIcon iconname={"critical"} />
                      </div>
                    </Tooltip>
                  )}
                  {selected_admission?.is_on_ventilator && (
                    <Tooltip title="Ventilator" arrow>
                      <div className="ventilator-icon-box">
                        <SvgFilesIcon iconname={"ventilator"} />
                      </div>
                    </Tooltip>
                  )}
                </>
                )}
              </div>
            </div>
          </div>
          <div className="patient-personal-info-box">
            <div className="patient-left-box">
              <div className="common-box">
                <span>Blood Group</span>
                <p>{patient?.blood_group ? patient?.blood_group : "-"}</p>
              </div>
              <div className="common-box">
                <span>Marital Status</span>
                <p>{patient?.maritial_status === "single"
                  ? "Single"
                  : patient?.maritial_status === "married"
                    ? "Married"
                    : "-"}</p>
              </div>
              <div className="common-box">
                <span>Mobile</span>
                <p> {patient?.emergency_contact_no
                  ? patient?.emergency_contact_no
                  : "-"}</p>
              </div>
              <div className="common-box">
                <span>Email</span>
                <p>{patient?.email ? patient?.email : "-"}</p>
              </div>
              <div className="common-box">
                <span>Aadhaar No.</span>
                <p>{patient?.aadhar_card_no
                  ? patient?.aadhar_card_no
                  : "-"}</p>
              </div>
              <div className="common-box">
                <span>Ayushman No.</span>
                <p>{patient?.ayushman_card_no
                  ? patient?.ayushman_card_no
                  : "-"}</p>
              </div>
            </div>
            <div className="patient-right-box">
              <h5>Emergency Contact</h5>
              <div className="right-box-inner">
                <div className="common-box">
                  <span>Contact Person</span>
                  <p> {patient?.emergency_contact_name
                    ? patient?.emergency_contact_name
                    : " -"}
                  </p>
                </div>
                <div className="common-box">
                  <span>Contact Number</span>
                  <p> {patient?.emergency_contact_no
                    ? patient?.emergency_contact_no
                    : " -"}</p>
                </div>
                <div className="common-box">
                  <span>Relationship : </span>{" "}
                  <p>{patient?.emergency_contact_relationship
                    ? patient?.emergency_contact_relationship
                    : " -"}
                  </p>
                </div>
                <div className="common-box">
                  <span>Address</span>
                  <p>{patient?.address ? patient?.address + "," : " "}
                    {patient?.city?.city
                      ? patient?.city?.city + ","
                      : " "}
                    {patient?.state?.state
                      ? patient?.state?.state + ","
                      : " "}
                    {patient?.country?.country
                      ? patient?.country?.country + "."
                      : " "}
                    {patient?.pincode ? patient?.pincode + "" : " "}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="patient-health-data-box">
            <div className="common-box box-1">
              <div className="icon-box">
                <SvgFilesIcon iconname={patient?.sex || "male"} />
                <h4>BLOOD PRESSURE</h4>
              </div>
              <h3> {selected_admission?.bp || "-"}</h3>
            </div>
            <div className="common-box box-2">
              <div className="icon-box">
                <SvgFilesIcon iconname={patient?.sex || "male"} />
                <h4>PULSE</h4>
              </div>
              <h3> {selected_admission?.pulse || "-"}</h3>
            </div>
            <div className="common-box box-3">
              <div className="icon-box">
                <SvgFilesIcon iconname={patient?.sex || "male"} />
                <h4>SPO2</h4>
              </div>
              <h3> {selected_admission?.spo2 || "-"}</h3>
            </div>
            <div className="common-box box-4">
              <div className="icon-box">
                <SvgFilesIcon iconname={patient?.sex || "male"} />
                <h4>TEMPERATURE</h4>
              </div>
              <h3> {selected_admission?.temperature || "-"}</h3>
            </div>
            <div className="common-box box-5">
              <div className="icon-box">
                <SvgFilesIcon iconname={patient?.sex || "male"} />
                <h4>INPUT</h4>
              </div>
              <h3> {selected_admission?.input || "-"}</h3>
            </div>
            <div className="common-box box-6">
              <div className="icon-box">
                <SvgFilesIcon iconname={patient?.sex || "male"} />
                <h4>OUTPUT</h4>
              </div>
              <h3> {selected_admission?.output || "-"}</h3>
            </div>
          </div>
        </div>

        <div className="patient-data-profile-box large-card patient-profile-box">
          <h4>Patient Profile</h4>
          <div className="overview-main">
            {/* Admission Info */}
            {(patientProfileData && patientProfileData?.odp) &&
              <div className="inner-box">
                <div className="inner-con-box">
                  <h6>ODP - {selected_admission?.admission_date ? formatDateToLocaleDateString(selected_admission?.admission_date) : formatDateToLocaleDateString(selected_admission?.created_on)}</h6>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(patientProfileData?.odp) }}></div>
                </div>
              </div>
            }
            {(patientProfileData?.genralExminations && Array.isArray(patientProfileData?.genralExminations) && patientProfileData?.genralExminations?.length > 0) &&
              <div className="inner-box">
                <div className="inner-con-box">
                  <h6>General Examination - {genralExminations[0]?.modified_on ? formatDateToLocaleDateString(genralExminations[0]?.modified_on) : genralExminations[0]?.created_on && formatDateToLocaleDateString(genralExminations[0]?.created_on)}</h6>
                  {patientProfileData?.genralExminations?.map((examination: any, index: number) => (
                    <li key={index}><b>{examination?.examination_type?.name}</b> - {examination?.note}</li>
                  ))}
                </div>
              </div>
            }
            {(patientProfileData?.systemicexaminations && Array.isArray(patientProfileData?.systemicexaminations) && patientProfileData?.systemicexaminations?.length > 0) &&
              <div className="inner-box">
                <div className="inner-con-box">
                  <h6>Systemic Examination - {systemicexaminations[0]?.modified_on ? formatDateToLocaleDateString(systemicexaminations[0]?.modified_on) : formatDateToLocaleDateString(systemicexaminations[0]?.created_on)}</h6>
                  {patientProfileData?.systemicexaminations?.map((examination: any, index: number) => (
                    <li key={index}><b>{examination?.examination_type?.name}</b> - {examination?.note}</li>
                  ))}
                </div>
              </div>
            }

            {(patientProfileData?.prescriptions && Array.isArray(patientProfileData?.prescriptions) && prescriptions.length > 0) && (
              <div className="inner-box">
                <div className="inner-con-box">
                  {prescriptions.some((pres) => {
                    const startDate = new Date(pres.start_date_time);
                    const endDate = new Date(pres.end_date_time);
                    const currentDate = new Date();
                    const isMatch =
                      startDate.getFullYear() <= currentDate.getFullYear() &&
                      startDate.getMonth() <= currentDate.getMonth() &&
                      startDate.getDate() <= currentDate.getDate() &&
                      endDate.getFullYear() >= currentDate.getFullYear() &&
                      endDate.getMonth() >= currentDate.getMonth() &&
                      endDate.getDate() >= currentDate.getDate();
                    return isMatch;
                  }) && (
                      <h6>
                        Prescriptions -{" "}
                        {prescriptions[0]?.modified_on
                          ? formatDateToLocaleDateString(prescriptions[0]?.modified_on)
                          : prescriptions[0]?.created_on &&
                          formatDateToLocaleDateString(prescriptions[0]?.created_on)}
                      </h6>
                    )}

                  {prescriptions.map((pres, index) => {
                    const startDate = new Date(pres.start_date_time);
                    const endDate = new Date(pres.end_date_time);
                    const currentDate = new Date();
                    const isMatch =
                      startDate.getFullYear() <= currentDate.getFullYear() &&
                      startDate.getMonth() <= currentDate.getMonth() &&
                      startDate.getDate() <= currentDate.getDate() &&
                      endDate.getFullYear() >= currentDate.getFullYear() &&
                      endDate.getMonth() >= currentDate.getMonth() &&
                      endDate.getDate() >= currentDate.getDate();
                    // Render only if there's a match
                    return isMatch ? (
                      <li key={index}>
                        <b>
                          {pres?.medicine_content?.brand_name}{" "}
                          {pres?.medicine_sub_type?.medicine_type?.name}
                        </b>{" "}
                        - {pres?.route} - <span> {pres?.frequency} </span>{" "}
                        {pres?.time && <span>-{" "}{"("}{pres?.time}{")"}</span>}
                      </li>
                    ) : null;
                  })}

                </div>
              </div>
            )}
            {patientProfileData && (patientProfileData?.family_blood_diseases?.length > 0 || patientProfileData?.injury?.length > 0 || patientProfileData?.behavioural_illness?.length > 0 || patientProfileData?.childhood_illness?.length > 0 || patientProfileData?.drug_allergies?.length > 0 || patientProfileData?.medical?.length > 0 || patientProfileData?.surgical?.length > 0 || patientProfileData?.family_history?.length > 0 || patientProfileData?.medication?.length > 0) &&
              <div className="inner-box">
                <div className="inner-con-box">
                  <h6>Past History - {patient?.modified_on ? formatDateToLocaleDateString(patient?.modified_on) : formatDateToLocaleDateString(patient?.created_on)}</h6>

                  {patientProfileData && patientProfileData?.childhood_illness?.length > 0 && <li><b>Systemic Illness : </b>
                    <ul className="list-inside">
                      {patientProfileData?.childhood_illness.map((childhoodIllness: any, index: any) => (
                        <li key={index}>{childhoodIllness?.disease?.name} - {childhoodIllness?.details}</li>
                      ))}
                    </ul>
                  </li>}

                  {patientProfileData && patientProfileData?.drug_allergies?.length > 0 && <li><b>Drug Allergies : </b>
                    <ul className="list-inside">
                      {patientProfileData?.drug_allergies.map((allergies: any, index: any) => (
                        <li key={index}>{allergies?.name} - {allergies?.reaction}</li>
                      ))}
                    </ul>
                  </li>}

                  {patientProfileData && patientProfileData?.behavioural_illness?.length > 0 && <li><b>Behavioural Illness : </b>
                    <ul className="list-inside">
                      {patientProfileData?.behavioural_illness.map((behaviouralIllness: any, index: any) => (
                        <li key={index}>{behaviouralIllness?.disease?.name} - {behaviouralIllness?.details}</li>
                      ))}
                    </ul>
                  </li>}

                  {patientProfileData && patientProfileData?.injury?.length > 0 && <li><b>Injury : </b>
                    <ul className="list-inside">
                      {patientProfileData?.injury.map((injury: any, index: any) => (
                        <li key={index}>{injury?.disease?.name} - {injury?.details}</li>
                      ))}
                    </ul>
                  </li>}

                  {patientProfileData && patientProfileData?.medical?.length > 0 && <li><b>Medical : </b>
                    <ul className="list-inside">
                      {patientProfileData?.medical.map((medicine: any, index: any) => (
                        <li key={index}>{medicine?.name + " - " + medicine?.details}</li>
                      ))}
                    </ul>
                  </li>}

                  {patientProfileData && patientProfileData?.surgical?.length > 0 && <li><b>Surgical : </b>
                    <ul className="list-inside">
                      {patientProfileData?.surgical.map((surg: any, index: any) => (
                        <li key={index}>{surg?.name + " - " + surg?.details}</li>
                      ))}
                    </ul>
                  </li>}

                  {patientProfileData && patientProfileData?.medication?.length > 0 && <li><b>Medications : </b>
                    <ul className="list-inside">
                      {patientProfileData?.medication.map((medi: any, index: any) => (
                        <li key={index}>{medi?.name + " - " + medi?.details}</li>
                      ))}
                    </ul>
                  </li>}
                  {patientProfileData && patientProfileData?.family_blood_diseases?.length > 0 && <li><b>Blood Disease : </b>
                    <ul className="list-inside">
                      {patientProfileData?.family_blood_diseases?.map((familyBloodDiseases: any, index: any) => (
                        <li key={index}>{familyBloodDiseases?.disease?.name + " - " + familyBloodDiseases?.details}</li>
                      ))}
                    </ul>
                  </li>}

                  {patientProfileData && patientProfileData?.family_history?.length > 0 && <li><b>Family History : </b>
                    <ul className="list-inside">
                      {patientProfileData?.family_history.map((familyhistory: any, index: any) => (
                        <li key={index}>{familyhistory?.relation + " - " + familyhistory?.details}</li>
                      ))}
                    </ul>
                  </li>}
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <div className="recent-reports-and-notifications-section">
        <div className="notifications-box">
          <div className="title-box">
            <h4>Notifications</h4>
            <div>
              <CustomMUIDatePicker
                name="selected_date"
                type="simple"
                setState={handleAdmissionDateChange}
                value={selected_date}
              />
            </div>
          </div>

          {allNotiData && allNotiData?.length > 0 ? (
            <ul>
              {allNotiData.map((notification, index) => getNotificationString(notification, index))}
            </ul>
          ) : (
            <SvgFilesIcon iconname={"nodatafound"} />
          )}
        </div>
        <div className="recent-reports-box">
          <div className="title-box">
            <h4>Recent Reports/Documents</h4>
          </div>
          {patientDocuments && patientDocuments?.length > 0 ? (
            <ul className="report-list">
              {patientDocuments.map(document => {
                // Extract file extension
                const fileExtension = document.doc_name.split('.').pop();
                // Determine icon class based on file extension
                const iconClass = getFileTypeIcon(fileExtension);
                return (
                  <li key={document.id}>
                    <i className={iconClass}></i>{" "}
                    {document?.document_type?.name} - {document?.doc_name} uploaded on {formatDateToLocaleDateString(document.doc_date)}
                    <a onClick={() => handleViewDocument(document.doc_path)}>
                      {/* <i className="text-success mdi mdi-eye-circle-outline"></i> */}
                      <i className="text-success mdi mdi-eye-circle-outline"></i>
                    </a>
                  </li>
                );
              })}
            </ul>
          ) : (
            <SvgFilesIcon iconname={"nodatafound"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientDetailDashboard;