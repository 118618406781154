import { patient_admission_hourly_report_routes } from "../api_path";
import axiosApi from "../../api/apiHelper";

export const postPatientAdmissionHourlyReport = async (values: any) => {
  try {
    const { data } = await axiosApi.post(
      patient_admission_hourly_report_routes.post_patient_admission_hourly_report,
      values
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const getPatientAdmissionHourlyReportByCategory = async (
  patient_admission_id: string,
  category: string,
  start_date: string,
  end_date: string,
) => {
  try {
    const { data } = await axiosApi.get(
      `${patient_admission_hourly_report_routes.patient_hourly_report_by_category}?patient_admission_id=${patient_admission_id}&category=${category}&start_date=${start_date}&end_date=${end_date}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const putPatientAdmissionHourlyReport = async (values: any) => {
  try {
    const { data } = await axiosApi.put(
      patient_admission_hourly_report_routes.put_patient_admission_hourly_report,
      values
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

// export const deletePatientAdmissionHourlyReport = async (id: string) => {
//   try {
//     const { data } = await axiosApi.delete(
//       `${patient_admission_hourly_report_routes.delete_patient_admission_hourly_report}/${id}`
//     );
//     return data;
//   } catch (error: any) {
//     return error?.response?.data;
//   }
// };

export const deletePatientAdmissionHourlyReport = async (id: string) => {
  try {
    const { data } = await axiosApi.delete(
      `${patient_admission_hourly_report_routes.delete_patient_admission_hourly_report}/${id}`
    );
    return data;
  } catch (error: any) {
    return error?.response?.data || { message: "An unexpected error occurred" };
  }
};


export const putPatientAdmissionHourlyReportBatchCall = async (values: any) => {
  try {
    const { data } = await axiosApi.put(
      patient_admission_hourly_report_routes.put_patient_admissio_hourly_report_batch,
      values
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
export const postPatientAdmissionHourlyReportBatchCall = async (values: any[]) => {
  try {
    const { data } = await axiosApi.post(
      patient_admission_hourly_report_routes.post_patient_admissio_hourly_report_batch,
      values
    );
    return data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
