import { Card, CardBody } from 'reactstrap';
import { Hospital, Patient } from '../../redux/types/patientAdmissionInstructionsTypes';
interface Props {
  hospitals?: Hospital[];
  allPatients?: Patient[];
  handlePatientsListClick: (hospital: Hospital) => void;
  hospitalWisePatientCount: any[]
}
const HospitalTable: React.FC<Props> = ({ handlePatientsListClick, hospitalWisePatientCount }) => {

  return (
    <div className="col-lg-6">
      <Card className="card-common-dashboard">
        <CardBody>
          <table className="table table-hover fixed-table-top">
            <thead className="table-light">
              <tr>
                <th>Sr</th>
                <th>Hospital Property</th>
                <th>Total Patient</th>
                <th>Length Of Stay</th>
                <th><i className="bx bxs-group font-size-16" /></th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(hospitalWisePatientCount) && hospitalWisePatientCount.length > 0 ? (
                hospitalWisePatientCount?.map((hospital: any, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{hospital?.landmark}</td>
                    <td>{hospital?.patient_count}</td>
                    <td>{hospital?.avg_length_of_stay != null ? hospital.avg_length_of_stay.toFixed(2) : "-"}</td>
                    <td ><a onClick={() => handlePatientsListClick(hospital)}><i className="bx bxs-group font-size-16" /></a></td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={hospitalWisePatientCount.length + 3} className="text-center">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
  );
};

export default HospitalTable;