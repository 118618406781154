import { useCallback, useEffect, useState } from "react";
import { Input, Label, Card, CardBody, CardTitle } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../redux/types/patientTabTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import {
  postPatientAdmission,
  putPatientPropertyAccess,
  searchPatient,
  updatePatient,
} from "../../redux/actions/patientAction";
import "../../assets/scss/teleicu/patients.scss";
import maleAvatar from "../../assets/images/users/male_avatar.png";
import femaleAvatar from "../../assets/images/users/female_avatar.png";
import { IPatientAdmissionParams } from "../../redux/types/patientTypes";
import { getDoctorListByRole } from "../../redux/actions/doctorAction";
import PatientHeader from "../../components/Header/PatientHeader";
 
 
interface doctorList {
  id: string;
  name: string;
  specialty_ids: string;
}
 
const PatientAdmission = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [aadhar_card_number, setAadhar_card_number] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [searchBy, setSearchBy] = useState<string>("");
  const [digital_sign, setDigital_sign] = useState<string>("");
  const { user_info } = useSelector((state: any) => state?.user);
  const [currentStep, setCurrentStep] = useState(1);
  const [patientDetails, setPatientDetails] = useState<any>({});
  const [patients, setPatients] = useState<any>([]);
  const { patientTabs, currentPatientActiveTab, patientTab } = useSelector((state: any) => state.patientTab);
  const [isCritical, setIs_Critical] = useState<boolean>(false);
  const [selectedDoctor, setSelectedDoctor] = useState<string>("");
  const [doctorTypes, setDoctorTypes] = useState<doctorList[]>([]);


   // Request notification permission
   const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    console.log("Notification permission:", permission);
    if (permission !== "granted") {
      console.error("Notification permission denied");
    }
  };
 
const scheduleCriticalNotification = () => {
    if (isCritical) {
      const fullName = `${patientDetails ?.first_name || ''} ${patientDetails ?.middle_name || ''} ${patientDetails ?.last_name || ''}`.toLowerCase();
      const notificationMessage = `Entellicu,A ${fullName} has been marked as critical.`
      setTimeout(() => {
        showNotification(notificationMessage);
      }, 60000); 
    }
  };
 

  const showNotification = (message:string) => {
    const notificationTitle = "Critical Patient Alert";
    const notificationOptions = {
      body: message,
      icon: "/logo64.png",
    };
    new Notification(notificationTitle, notificationOptions);
  };
 
useEffect(() => {
    requestNotificationPermission();
  }, []);
 
  useEffect(() => {
    scheduleCriticalNotification();
  }, [isCritical]);
 
 
 
 
  const handleReset = () => {
    setSearch("");
    setAadhar_card_number("");
    setIs_Critical(false);
    setDigital_sign("");
    setSelectedDoctor("");
    // resetForm();
    setCurrentStep(1);
    setPatients([])
    setPatientDetails({})
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { body } = await getDoctorListByRole({ role: "doctor", hospital_id: user_info?.hospital_property?.hospital?.id });
        if (body) setDoctorTypes(body?.data);
        else setDoctorTypes([]);
      } catch (error) {
        console.error("Error fetching doctor types:", error);
 
      }
    };
 
    fetchData();
  }, []);
 
  /* FORMIK VALIDATION SCHEMA */
  const validationSchema = Yup.object().shape({
    hospital_property_id: Yup.string(),
    patient_id: Yup.string(),
  });
 
  /* FORMIK INITIAL VALUES */
  const initialValues = {
    hospital_property_id: "",
    patient_id: "",
    is_critical: false,
    admission_by_doctor: ""
  };
 
  const handleSearchPatient = async () => {
    try {
      if (searchBy.trim() === "")
        return ErrorToast("Please select search By values");
      if (search.trim() === "") return ErrorToast("Search is required field!");
 
      const response = await searchPatient(searchBy, search);
      if (response?.statusCode === 200) {
        setPatients(response?.body?.data);
        setCurrentStep(2);
      } else if (response.statusCode === 400) {
        setPatients([]);
        setCurrentStep(1);
        ErrorToast(response?.errors[0]?.msg);
      }
    } catch (error) { }
  };
  const toggle = useCallback(
    (tab: string) => {
      if (currentPatientActiveTab !== tab) {
        if (location?.pathname !== "/patients") {
          navigate("/patients");
        }
        dispatch({
          type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
          payload: {
            id: tab,
          },
        });
      }
    },
    [currentPatientActiveTab, dispatch, location?.pathname, navigate]
  );
 
  const handlePatientPropertyAccessChange = async () => {
    let current_date: Date = new Date();
 
    if (selectedDoctor.trim() === "")
      return ErrorToast("Doctor selection is a required field!");
    if (patientDetails?.discharge === false)
      return ErrorToast("Patient is already admitted in hospital!");
 
    const concatPropertyHasAccess: string[] = [
      ...patientDetails?.property_has_access,
      user_info?.hospital_property?.id,
    ];
 
    // Check if the hospital property already has access
    if (
      patientDetails.property_has_access?.includes(
        user_info?.hospital_property?.id
      )
    ) {
      // If the property has access, proceed with admission
      await postPatientAdmission({
        hospital_property_id: user_info?.hospital_property?.id,
        patient_id: patientDetails?.id,
        admission_date_entry: current_date.toJSON(),
        admitted: true,
        is_critical: isCritical,
        admission_by_doctor: selectedDoctor,
      });
      await updatePatient({
        id: patientDetails?.id,
        current_admitted_hospital_property: user_info?.hospital_property?.id,
        discharge: false,
      });
      SuccessToast("Patient Admitted Successfully");
      handleReset();
    } else {
      // Add property access before admitting
      await putPatientPropertyAccess(
        patientDetails?.id,
        concatPropertyHasAccess
      );
      await updatePatient({
        id: patientDetails?.id,
        current_admitted_hospital_property: user_info?.hospital_property?.id,
        discharge: false,
      });
      await postPatientAdmission({
        hospital_property_id: user_info?.hospital_property?.id,
        patient_id: patientDetails?.id,
        admission_date_entry: current_date.toJSON(),
        admitted: true,
        is_critical: isCritical,
        admission_by_doctor: selectedDoctor,
      });
 
      SuccessToast("Patient Admitted Successfully");
      handleReset();
    }
 
    dispatch({
      type: ActionTypes.SET_Main_Patient_TAB,
      payload: { patientMainTab: "1" },
    });
 
    // Prepare the tab information with new data
    let tab_info = {
      id: patientDetails?.id,
      name:
        patientDetails?.first_name +
        " " +
        patientDetails?.middle_name +
        " " +
        patientDetails?.last_name,
    };
 
    // Temporarily clear the active tab to ensure a fresh state
    dispatch({
      type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
      payload: null,
    });
 
    // Delay the tab opening to allow React to re-render with new data
    setTimeout(() => {
      if (patientTabs?.some((tab: any) => tab?.id === patientDetails?.id)) {
        // If the tab already exists, remove and reopen it with new data
        toggle(patientDetails?.id); // Toggle the tab view
        dispatch({
          type: ActionTypes.SET_Patient_TAB,
          payload: tab_info,
        });
      } else {
        // Add a new patient tab
        dispatch({
          type: ActionTypes.ADD_Patient_TAB,
          payload: tab_info,
        });
        toggle(patientDetails?.id); // Toggle the tab view
        dispatch({
          type: ActionTypes.SET_Patient_TAB,
          payload: tab_info,
        });
      }
 
      // Now activate the patient tab (this ensures the tab is focused)
      dispatch({
        type: ActionTypes.SET_CURRENT_PATIENT_ACTIVE_TAB,
        payload: tab_info, // Set the current active tab to the new/updated patient tab
      });
    }, 0); // Use setTimeout to ensure the state updates before rendering the tab
 
    // Set the current step in the process
    setCurrentStep(1);
  };
 
  const handleSubmit = async (
    values: IPatientAdmissionParams,
    { resetForm }: any
  ) => { };
 
  // const handleSubmit = async (
  //   values: IPatientAdmissionParams,
  //   { resetForm }: any
  // ) => {
  //   // Your submission logic here
  //   // After successful submission, reset the form
  //   handleReset(resetForm);
  // };
 
 
  const handleSelectedPatient = (patient: any) => {
    setPatientDetails(patient);
    setCurrentStep(3);
  };
 
  return (
    <>
      <div className="row scroll-bar-patient common-top-con">
        <div className="col-12">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isValid,
              isSubmitting,
              handleSubmit,
              resetForm,
              values,
              handleChange,
              errors,
              touched,
              handleBlur,
              dirty,
              setFieldValue,
            }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (errors) {
                    const firstError: any = Object.values(errors)[0];
                    if (Array.isArray(firstError)) {
                      const errorMessage = firstError.join(", ");
                      ErrorToast(errorMessage);
                    } else ErrorToast(firstError);
                  }
                  handleSubmit();
                }}
              >
                <>
                  <Card>
                    <CardBody>
                      {/* <ul className="step">
                        <li><a className={`count ${currentStep === 1 ? "active" : "" }`}>1</a></li>
                        <li><a className={`count ${currentStep === 2 ? "active" : "" }`}>2</a></li>
                      </ul> */}
                      <div className="step-one">
                        <CardTitle className="h4 hospital-card-title text-primary">
                          Find patient
                        </CardTitle>
                        <div className="row">
                          <div className="col-12">
                            <Label className="form-label"> Search By</Label>
                            <div className="d-block mb-3">
                              <div className="form-check d-inline-block mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="searchBy"
                                  id="name"
                                  value="name"
                                  checked={searchBy === "name"}
                                  placeholder="Enter Name"
                                  onChange={(event) =>
                                    setSearchBy(event.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="name"
                                >
                                  Name
                                </label>
                              </div>
                              <div className="form-check d-inline-block mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="searchBy"
                                  id="aadhar_no"
                                  value="aadhar_no"
                                  checked={searchBy === "aadhar_no"}
                                  placeholder="Enter Aadharcard Number"
                                  onChange={(event) =>
                                    setSearchBy(event.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="aadhar_no"
                                >
                                  Aadharcard Number
                                </label>
                              </div>
                              <div className="form-check d-inline-block mr-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="searchBy"
                                  id="phone_number"
                                  value="phone_number"
                                  checked={searchBy === "phone_number"}
                                  placeholder="Enter Phone Number"
                                  onChange={(event) =>
                                    setSearchBy(event.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="phone_number"
                                >
                                  Phone Number
                                </label>
                              </div>
                              <div className="form-check d-inline-block ">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="searchBy"
                                  id="unique_identity"
                                  value="unique_identity"
                                  checked={searchBy === "unique_identity"}
                                  placeholder="Enter Unique Identity"
                                  onChange={(event) =>
                                    setSearchBy(event.target.value)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="unique_identity"
                                >
                                  Unique Identity
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">Search</Label>
                              <Input
                                name="search"
                                className="form-control"
                                type="text"
                                value={search}
                                placeholder="Enter"
                                onChange={(event) =>
                                  setSearch(event?.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 button-box text-center">
                            <button
                              className="btn btn-primary  mr-2 medium-btn"
                              type="button"
                              onClick={() => handleSearchPatient()}
                            >
                              Search
                            </button>
                            <button
                              className="btn btn-secondary medium-btn"
                              type="button"
                              onClick={() => handleReset()}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
 
                  {patients.length > 0 &&
                    <Card>
                      <CardBody>
                        {/* PATIENTS CARDS */}
                        {currentStep > 1 && (
 
                          <div className="row">
 
                            {patients.map((patient: any) => {
                              return (
                                <div
                                  className="col-md-6 col-lg-3 my-3"
                                  key={patient?.id}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleSelectedPatient(patient)}
                                >
                                  <Card className={`border border-2 ${patientDetails?.id === patient?.id ? 'border-info' : ""}`}>
                                    <CardBody>
                                      <CardTitle className="h4 hospital-card-title  d-flex align-items-center justify-content-center">
                                        <div className="left-logo">
                                          <img src={patient?.sex === "female" ? femaleAvatar : maleAvatar} alt="#"></img>
                                        </div>
                                        <div className="right-detail">
                                          {patient?.first_name} {patient?.middle_name} {patient.last_name}
                                          <p className="text-muted">
                                            {patient?.address} {patient?.city?.city} {patient?.state?.state}{" "}
                                            {patient?.country?.country}{" "}
                                          </p>
                                          <p className="text-muted">
                                            Patient Id : {patient?.id?.slice(1, 8)}
                                          </p>
                                        </div>
                                      </CardTitle>
                                    </CardBody>
                                  </Card>
                                </div>
                              );
                            })}
 
                          </div>)}
                        {currentStep > 2 && (
                          <div className="second">
 
                            <CardTitle className="h4 hospital-card-title text-primary">
                              Aadhar verification
                            </CardTitle>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-3">
                                  <Label className="form-label">Aadhar NO.</Label>
                                  <Input
                                    name="aadhar_card_number"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Aadhar NO"
                                    value={aadhar_card_number}
                                    onChange={(e) =>
                                      setAadhar_card_number(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
 
                              <div className="col-12">
                                <div className="mb-3">
                                  <div className="form-check d-inline-block mr-2">
                                    <Label className="form-check-label" check>
                                      <Input className="form-check-input" type="checkbox" checked={isCritical} onChange={(event) =>
                                        setIs_Critical(event.target.checked)} />
                                      Is Critical
                                    </Label>
                                  </div>
                                </div></div>
                              {/* <div className="col-12">
                              <div className="mb-3">
                                <Label className="form-label">
                                  Digital Sign
                                </Label>
                                <Input
                                  name="digital_sign"
                                  className="form-control sign-box"
                                  type="text"
                                  placeholder="Sign"
                                  value={digital_sign}
                                  onChange={(event) =>
                                    setDigital_sign(event.target.value)
                                  }
                                />
                              </div>
                            </div> */}
                              <div className="col-12">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Admission Doctor
                                  </Label>
                                  <select className="form-control" name="admission_by_doctor" onChange={(e) => setSelectedDoctor(e.target.value)}          >
                                    <option value="">Select a Doctor</option>
                                    {doctorTypes && doctorTypes.length > 0 && doctorTypes.map(doctor => (
                                      <option key={doctor?.id} value={doctor?.id}>{doctor?.name}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-12 button-box text-center mb-3">
                                <button
                                  className="btn btn-primary  mr-2 medium-btn"
                                  type="button"
                                  onClick={() =>
                                    handlePatientPropertyAccessChange()
                                  }
                                >
                                  Next
                                </button>
                                <button
                                  className="btn btn-secondary medium-btn"
                                  type="button"
                                  onClick={() => handleReset()}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
 
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  }
                </>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
 
export default PatientAdmission;
 