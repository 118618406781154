import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, Table } from "reactstrap";
import classnames from 'classnames';
import SvgFilesIcon from "../SvgFiles/SvgFiles";
 
interface DataModelComponentProps<T> {
  isOpen: boolean;
  toggle: () => void;
  title: string;
  data: T[];
  renderRow: (item: T, index: number, activeTab: string) => JSX.Element | null;
  showTabs?: boolean;
  columns?: { tab: string; columns: string[] }[];
}
 
const DataModelComponent: React.FC<DataModelComponentProps<any>> = ({
  isOpen,
  toggle,
  title,
  data,
  columns,
  renderRow,
  showTabs = false,
}) => {
  const [activeTab, setActiveTab] = useState('1');
 
  const toggleTab = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
 
  const filteredData = data?.filter(item => {
    if (activeTab === '1') {
      return !item?.discharged;
    } else if (activeTab === '2') {
      return item?.discharged;
    }
    return false;
  });
 
  const getColumns = () => {
    return columns?.find(column => column?.tab === activeTab)?.columns || [];
  };
 
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" style={{ width: '70%', maxWidth: '70%' }}>
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      {showTabs && (
        <Nav tabs className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto" style={{ marginLeft: '10px' }}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => { toggleTab('1'); }}
            >
              Admitted Patient
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => { toggleTab('2'); }}
            >
              Discharge Patient
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <ModalBody className="dashboard-model">
        <Table className="table table-hover">
          {filteredData.length > 0 ? (
            <>
              <thead className="table-light table-header-dashboard">
                <tr>
                  {getColumns().map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((item, index) => renderRow(item, index, activeTab))}
              </tbody>
            </>
          ) : (
            <div className="no-data-found">
              <SvgFilesIcon iconname={"nodatafound"} />
            </div>
          )}
        </Table>
      </ModalBody>
    </Modal>
  );
};
 
export default DataModelComponent;
 
 